import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/http/auth.service';
import { IUser } from './models/auth.interface';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  @ViewChild('passwordEyeRegister') passwordEye;
  loginForm: UntypedFormGroup;
  wrongLogin = false;

  // Seleccionamos el elemento con el nombre que le pusimos con el #
  passwordTypeInput = 'password';
  // Variable para cambiar dinamicamente el tipo de Input que por defecto sera 'password'
  iconpassword = 'eye-off';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private loadingController: LoadingController
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Login
   *
   * @summary login
   * @returns Observable<void>IUserResponse
   */
  async login() {
    if (this.loginForm.valid) {
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Por favor espere...',
        duration: 1000,
      });
      await loading.present();

      const user: IUser = {
        username: this.f.username.value,
        password: this.f.password.value,
      };
      this.authService.login(user).subscribe(
        async (userResp) => {
          this.wrongLogin = false;
          const user = {
            token: userResp.token
          }
          await localStorage.setItem('user', JSON.stringify(userResp.token));
          await this.loginForm.reset();
          await this.router.navigate(['home']);
          const { role, data } = await loading.onDidDismiss();
        },
        async (error) => {
          this.wrongLogin = true;
          const { role, data } = await loading.onDidDismiss();
        }
      );
    }
  }

  /**
   * Toggle password mode
   *
   * @summary show or hide password
   * @returns Observable<void>
   */
  togglePasswordMode() {
    this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
    this.iconpassword = this.iconpassword === 'eye-off' ? 'eye' : 'eye-off';
  }
}
