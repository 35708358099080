import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  IProjectsResponse,
  IUserData,
} from './home/models/projects.interface';
import { filter } from 'rxjs/operators';
import { AppService } from 'src/http/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [StatusBar],
})
export class AppComponent implements OnInit, OnDestroy {
  projectTitle: string;
  previousUrl: string;
  currentUrl: string;
  userData: IUserData;
  userDataSub: Subscription;
  projectTitleSub: Subscription;

  constructor(
    private router: Router,
    private statusBar: StatusBar,
    private appService: AppService
  ) {
    this.statusBar.styleDefault();
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.appService.setPreviousUrl(this.previousUrl);
        this.currentUrl = event.url;
      });
  }

  ngOnInit() {
    if (!localStorage.getItem('user')) {
      this.router.navigateByUrl('login', {
        replaceUrl: true,
      });
    } else {
      this.router.navigate(['home']);
    }
  }


  ngOnDestroy(): void {
    this.userDataSub?.unsubscribe();
    this.projectTitleSub?.unsubscribe();
  }

  /**
   * logOut
   *
   * @summary logOut
   * @returns Observable<void>IUserResponse
   */
  logOut() {
    localStorage.removeItem('user');
    this.router.navigateByUrl('login', {
      replaceUrl: true,
    });
  }

  goToHome() {

    this.router.navigateByUrl('home', {
      replaceUrl: true,
    });
  }
}
